<template>
  <div id="app" class="light">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>
