import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// Router.beforeResolve((to, from, next) => {
// // If this isn't an initial page load.
//     if (to.name) {
//         // Start the route progress bar.
//         NProgress.start()
//     }
//     next()
// })
  
// Router.afterEach((to, from) => {
//     // Complete the animation of the route progress bar.
//     NProgress.done()
// })

export default new Router({
    routes: [
        {
            path: '/',
            name: 'HomePage',
            component: () => import('../view/HomePage'),
        },
        {
            path: '/about',
            name: 'AboutPage',
            component: () => import('../view/AboutPage'),
        },
    ],
});